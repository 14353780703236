<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar size="40" :src="appLogoImage" variant="light-primary" />
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t("Tancar sessió") }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import { avatarText } from "@core/utils/filter";
// import useBasicAuth from "@/auth/basic/useBasicAuth";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
  data() {
    return {
      avatarText,
    };
  },
  methods: {
    logout() {
      // useBasicAuth.logout();

      // Remove userData from localStorage
      localStorage.removeItem("foravilaAdminUserData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "foravila-login" });
    },
  },
};
</script>
